<template>
  <el-container>
    <div class="footer-nav dio">
      <div class="footer-container">
        <div class="left-footer">
          <div class="left-footer-nav">
            <div class="footer-nav-item" v-for="nav in navlist" :key="nav.key">
              <div class="title">{{ nav.title }}</div>
              <div v-for="child in nav.children" :key="child.title">
                <a class="link" :href="child.link" :title="child.title">{{
                  child.title
                }}</a>
              </div>
            </div>
          </div>
          <div class="dio">
            <span style="margin-right: 50px"
              >联系电话：<a :href="'tel:'+websiteInfo.contactPhone" style="color:#fff;">{{ websiteInfo.contactPhone }}</a></span
            >
            <span>公司地址：{{ websiteInfo.firmAddress }}</span>
          </div>
        </div>
        <div class="right-footer">
          <div class="wx-imgs">
            <img :src="websiteInfo.serviceWx" alt />
            <span>客服微信</span>
          </div>
          <div class="wx-imgs">
            <img :src="websiteInfo.app" alt />
            <span>农极客APP</span>
          </div>
        </div>
        <div class="side-item" v-show="showScrollTop">
          <div class="side-line" @click="toTop">
            <div>
              <span class="iconfont icon-huidingbu"></span>
              <span>返回顶部</span>
            </div>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <el-footer height="56px" class="dio">
      <span style="margin-right: 30px"
        >版权所有：郑州木加林文化传播有限公司</span
      >
      <a href="sitemap.html" style="margin-right: 30px">网站地图</a>
      <span>网站备案 / 许可证号：</span>
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010502003873"
        target="_blank"
        class="text-white"
        title="农极客官网"
        rel="nofollow"
        >豫公网安备41010502003873号</a
      >&nbsp;/&nbsp;
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        class="text-white"
        title="农极客官网"
        rel="nofollow"
        >豫ICP备14010072号-3</a
      >
    </el-footer>
    <div class="bottom-nav">
      <a href="/">网站首页</a>
      <a href="zxly_u.html">在线留言</a>
      <a href="tel:400-800-8084">联系电话</a>
    </div>
  </el-container>
</template>

<script>
import $ from "../assets/js/jquery.min.js";
export default {
  data() {
    return {
      name: "",
      phone: "",
      address: "",
      job: "种植户",
      msg: "",
      showWindow: false,
      sending: false,
      sended: false,
      intervalNum: 8,
      activeIndex: "/",
      showScrollTop: false,
      year: new Date().getFullYear(),

      navlist: [
        {
          title: "农资商城",
          key: "shop",
          link: "nzsc.html?type=1",
          children: [
            {
              title: "热销爆款",
              link: "nzsc.html?type=1",
            },
            {
              title: "根施专区",
              link: "nzsc.html?type=2",
            },
            {
              title: "叶喷专区",
              link: "nzsc.html?type=3",
            },
            {
              title: "套餐专区",
              link: "nzsc.html?type=4",
            },
          ],
        },
        {
          title: "应用案例",
          key: "research",
          link: "zwal.html",
          children: [
            // {
            //   title: "产品案例",
            //   link: "cpal.html",
            // },
            {
              title: "作物案例",
              link: "zwal.html",
            },
          ],
        },
        {
          title: "互动社区",
          key: "community",
          link: "hdsq.html",
          children: [
            {
              title: "活动",
              link: "hdsq.html",
            },
            {
              title: "问答",
              link: "wdsq.html",
            },
          ],
        },
        {
          title: "新闻中心",
          key: "news",
          link: "xwzx.html?type=0",
          children: [
            {
              title: "企业动态",
              link: "xwzx.html?type=0",
            },
            {
              title: "行业新闻",
              link: "xwzx.html?type=1",
            },
          ],
        },
        {
          title: "联系我们",
          key: "about",
          link: "gywm_u.html",
          children: [
            {
              title: "关于我们",
              link: "gywm_u.html",
            },
            {
              title: "招商合作",
              link: "zshz_u.html",
            },
            {
              title: "招贤纳士",
              link: "zxns_u.html",
            },
            {
              title: "销售网点",
              link: "xswd_u.html",
            },
            {
              title: "在线留言",
              link: "zxly_u.html",
            },
          ],
        },
      ],
      application: {
        title: "应用案例",
        router: "/application/prolist",
        key: "application",
        child: [
          {
            name: "市场反馈",
            router: "/application/prolist",
          },
          {
            name: "作物管理方案",
            router: "/application/croplist",
          },
        ],
      },
      society: {
        title: "社区",
        router: "/society/asklist",
        key: "society",
        child: [
          {
            name: "农技问答",
            router: "/society/asklist",
          },
          {
            name: "活动",
            router: "/society/activelist",
          },
        ],
      },
      componey: {
        title: "联系我们",
        router: "/componey/aboutus",
        key: "componey",
        child: [
          {
            name: "关于我们",
            router: "/componey/aboutus",
          },
          {
            name: "招商",
            router: "/componey/business",
          },
          {
            name: "招聘",
            router: "/componey/recruit",
          },
          {
            name: "销售网点",
            router: "/componey/map",
          },
          {
            name: "在线联系",
            router: "/componey/conect",
          },
        ],
      },
      product: {
        title: "产品中心",
        router: `/product/`,
        key: "product",
        child: [],
      },
      footerList: [],
      showSearch: false,
      searchTxt: "",
      websiteInfo: {
        app: "http://m.meitiannongzi.com/1598606092088.png",
        contactPhone: "400-800-8084",
        firmAddress: "河南省郑州市金水区金成国际广场",
        infoId: 1,
        serviceWx: "http://m.meitiannongzi.com/1598859105077.png",
      },
      path: location.href,
    };
  },
  //   computed: mapState(["product", "websiteInfo"]),
  // beforeRouteUpdate(to, from, next) {
  //   // just use `this`
  //   console.log("beforeRouteUpdate", to);
  //   // this.updatepath();
  //   // setTimeout(() => {
  //   //   this.updatepath();
  //   // }, 400);
  //   next();
  // },
  methods: {
    search() {
      console.log(this.searchTxt);
      if (this.searchTxt == "") {
        this.$message.error("搜索关键词不能为空！");
        return false;
      }
      this.showSearch = false;
      this.$router.push(`/search/${this.searchTxt}`);
      this.searchTxt = "";
    },
    toTop() {
      $("html,body").animate({ scrollTop: "0px" }, 300);
    },
    clickSendHandle() {
      if (!this.name) {
        this.$alert("请输入您的姓名", "缺少必填项");
        return false;
      } else if (!this.phone) {
        this.$alert("请输入您的电话", "缺少必填项");
        return false;
      } else if (!this.address) {
        this.$alert("请输入地址", "缺少必填项");
        return false;
      } else if (!this.msg) {
        this.$alert("请输入咨询内容", "缺少必填项");
        return false;
      }
      if (this.sending) {
        return false;
      }
      this.sending = true;
      console.log("click");
      this.$axios
        .post(
          `contact/addContact`,
          {
            name: this.name,
            phone: this.phone,
            address: this.address,
            job: this.job,
            msg: this.msg,
            clickStatus: 0,
          },
          {
            header: {
              contentType: "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.sending = false;
          if (res.status == 200) {
            this.$alert("提交成功！稍后会有客服和您联系。");
            this.sended = true;
            let timer = setInterval(() => {
              this.intervalNum--;
              if (this.intervalNum <= 0) {
                clearInterval(timer);
                this.showWindow = false;
                this.sended = false;
                this.intervalNum = 8;
                this.msg = "";
                this.name = "";
                this.phone = "";
                this.address = "";
              }
            }, 1000);
          } else {
            this.$alert(`提交失败！${res.information}`);
          }
        });
    },
  },
  created() {
    this.activeIndex = this.path;
  },
  mounted() {
    // let arr = [];
    // this.$axios.post(
    //   "/api/urls?site=https://www.nongjike.cn&token=U4KYKk3rVbpFf0sT"
    // );
    // this.$axios.get(`ask/websiteAskList?pageNum=1&pageSize=10`).then((res) => {
    //   let data = res.data;
    //   this.$store.commit("setasklist", data.information.list);
    //   this.$store.commit("setasktotal", data.information.total);
    // });
  },
};
</script>

<style lang="scss">
.jojo{
  display: none;
}
.footer-nav {
  width: 100%;
  background: #2a2a2a;
  min-height: 100px;
  padding: 40px 0;
  display: flex;
  .footer-container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left-footer {
    flex-shrink: 0;
    width: 100px;
    flex-grow: 1;
    color: #fff;
    font-size: 16px;

    .left-footer-nav {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      margin-bottom: 18px;

      .footer-nav-item {
        width: 20%;
        text-align: left;

        .title {
          font-size: 16px;
          color: #fff;
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }
        }

        .link {
          font-size: 14px;
          display: block;
          color: #ddd;
          margin-bottom: 6px;
          transition: all 0.3s ease-in-out;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .right-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    img {
      width: 120px;
      height: 120px;
      display: block;
      margin-bottom: 2px;
    }
    .wx-imgs {
      width: 120px;
      text-align: center;
      font-size: 14px;
      color: #fff;
      line-height: 20px;
      margin-left: 80px;
      &:first-child {
        margin-left: 99px;
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 1px;
          height: 160px;
          background: #6a6a6a;
          position: absolute;
          left: -99px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
.el-container {
  min-width: 960px;
}

.el-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e1e1e;
  line-height: 56px;
  color: #cccccc;
  a {
    color: #cccccc;
    text-decoration: underline;
  }
}
.bottom-nav{
  display: none;
}
@media screen and (max-width: 1200px) {
  .swiper-cont {
    .swiper-button-prev {
      width: calc(2px * 56 / 3);
      height: calc(2px * 120 / 3);
    }
    .swiper-button-next {
      width: calc(2px * 56 / 3);
      height: calc(2px * 120 / 3);
    }
  }
  .bottom-nav{
    display: flex;
    position: fixed;
    z-index: 10;
    bottom: 0;
    width:100vw;
    height: 45px;
    line-height: 45px;
    justify-content: flex-start;
    align-items: center;
    a{
      display: block;
      text-align: center;
      text-decoration: none;
      color:#fff;
      background: #65b825;
      width: 100px;
      flex-grow: 1;
      flex-shrink: 0;
      border-right: 1px solid #fff;
      &:last-child{
        border-right: none;
      }
    }
  }
  .side-bar {
    right: -16px;
    transform: scale(0.8);
    -ms-transform: scale(0.8); /* IE 9 */
    -moz-transform: scale(0.8); /* Firefox */
    -webkit-transform: scale(0.8); /* Safari 和 Chrome */
    -o-transform: scale(0.8); /* Opera */

    transform-origin: 0 0;
    -ms-transform-origin: 0 0; /* IE 9 */
    -moz-transform-origin: 0 0; /* Firefox */
    -webkit-transform-origin: 0 0; /* Safari 和 Chrome */
    -o-transform-origin: 0 0; /* Opera */
  }
  .connect-window {
    transform: scale(0.8);
    -ms-transform: scale(0.8); /* IE 9 */
    -moz-transform: scale(0.8); /* Firefox */
    -webkit-transform: scale(0.8); /* Safari 和 Chrome */
    -o-transform: scale(0.8); /* Opera */

    transform-origin: 0% 100%;
    -ms-transform-origin: 0 100; /* IE 9 */
    -moz-transform-origin: 0 100; /* Firefox */
    -webkit-transform-origin: 0 100; /* Safari 和 Chrome */
    -o-transform-origin: 0 100; /* Opera */
  }
}

@media screen and (min-width: 320px) and (max-width: 1200px) {
  .footer-nav .footer-container{
    width: 100%;
    padding: 0 15px;
  }
  .el-footer.jojo{
    display: block;
    line-height: 25px;
    padding-top:5px;
  }
  .footer-nav .right-footer{
    display: none;
  }
  #app .navi-container .navi-list .navi-item.dio{
    display: none;
  }
  .dio{
    display: none;
  }
  .jojo{
    display: block;
    font-size: 12px;
  }
  .footer-nav .left-footer .left-footer-nav{
    display: none;
  }
  .footer-nav .left-footer .left-footer-nav .footer-nav-item .title{
    font-size: 12px;
  }
  .footer-nav{
    padding: 20px 0;
  }
  .footer-nav .left-footer .left-footer-nav .footer-nav-item .link{
    font-size: 12px;
  }
  .el-container.is-vertical{
    min-width: auto;
  }
}
</style>
