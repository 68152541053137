<template>
  <div class="about">
    <div class="title">相关阅读</div>
    <div class="links" v-if="type != 1 && type!=2">
      <div class="link" v-for="item in list" :key="item.researchId">
        <a
          :href="
            'yyal.html?id=' +
                item.researchId
          "
          :title="item.subject"
          >{{ item.subject }}</a
        >
      </div>
    </div>
    <div class="links" v-if="type == 1">
      <div class="link" v-for="item in list" :key="item.id">
        <a
          :href="
            'xwxq.html?id=' +
            item.id +
            '&type=' +
            item.status
          "
          :title="item.title"
          >{{ item.title }}</a
        >
      </div>
    </div>
    <div class="links" v-if="type == 2">
      <div class="link" v-for="item in list" :key="item.galleryId">
        <a
          :href="
            'bchxq.html?id=' +
            item.galleryId
          "
          :title="item.cropName+ item.name"
          >{{ item.cropName+ item.name }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "adv",
  props: ["list", "type"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.about {
  width: 100%;
  border: 1px solid #e6e6e6;
  background: #f5f5f5;
  padding:0 22px;
  .title {
    font-size: 18px;
    color: #333;
    padding: 15px 0 0;
    margin-bottom: 15px;
    font-weight: bold;
  }
  .links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .link {
      width: 48%;
      margin-right: 15px;
      margin-bottom: 15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-shrink: 0;
      position: relative;
      padding-left: 20px;
      &::before{
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        position: absolute;
        background: #65b825;
        top:50%;
        left:0;
        transform: translateY(-50%);
      }
      a {
        color: #666;
        &:hover {
          color: #65b825;
        }
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .about .links .link{
    width: 100%;
  }
}
</style>
