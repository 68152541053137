<template>
  <el-container>
    <header>
      <div class="login_container">
        <div class="login_header">
          <div class="login_t">欢迎来到农极客官网！改土生根，选农极客。</div>
          <div class="login_item">
            <div class="login_name" v-if="!!USER.nickName">
              {{ USER.nickName }}
            </div>
            <div class="login_btns" v-if="!USER.nickName">
              <a href="login.html?type=0">登录</a> /
              <a href="login.html?type=1">注册</a>
            </div>
            <div class="login_btns" v-if="!!USER.nickName">
              <a style="cursor: pointer" @click="quitLogin">退出</a>
            </div>
          </div>
        </div>
      </div>

      <div class="headers">
        <a
          class="header-icon"
          href="https://www.nongjike.cn"
          title="农极客官网"
        >
          <img src="../assets/img/njklogo.png" alt />
        </a>
        <div class="toggle-btn" v-if="!showNav" @click="showNav = !showNav">
          <svg t="1630382418428" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6102" width="32" height="32"><path d="M834.8 448 189.2 448C155.4 448 128 476.6 128 512c0 35.4 27.4 64 61.2 64l645.6 0c33.8 0 61.2-28.6 61.2-64C896 476.6 868.6 448 834.8 448z" p-id="6103" fill="#666666"></path><path d="M834.8 192 189.2 192C155.4 192 128 220.6 128 256c0 35.4 27.4 64 61.2 64l645.6 0c33.8 0 61.2-28.6 61.2-64C896 220.6 868.6 192 834.8 192z" p-id="6104" fill="#666666"></path><path d="M834.8 704 189.2 704C155.4 704 128 732.6 128 768c0 35.4 27.4 64 61.2 64l645.6 0c33.8 0 61.2-28.6 61.2-64C896 732.6 868.6 704 834.8 704z" p-id="6105" fill="#666666"></path></svg>
        </div>
        <div class="toggle-btn" v-if="showNav" @click="showNav = !showNav">
          <svg t="1630391832713" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9422" width="32" height="32"><path d="M810 273.596 750.404 214 512 452.404 273.596 214 214 273.596 452.404 512 214 750.404 273.596 810 512 571.596 750.404 810 810 750.404 571.596 512Z" p-id="9423" fill="#666666"></path></svg>
        </div>
        <div class="header-nav">
          <div
            class="header-nav-item"
            :class="{ active: path.indexOf('index') >= 0 }"
          >
            <a class="main-nav" href="https://www.nongjike.cn">首页</a>
          </div>
          <div
            class="header-nav-item"
            v-for="nav in navlist"
            :key="nav.key"
            :class="{ active: path.indexOf(nav.key) >= 0 }"
          >
            <a :href="nav.link" class="main-nav">{{ nav.title }}</a>
            <div
              class="nav-items"
              v-if="nav.children && nav.children.length > 0"
            >
              <div>
                <a
                  :href="child.link"
                  v-for="child in nav.children"
                  :key="child.link"
                  >{{ child.title }}</a
                >
              </div>
            </div>
          </div>
          <div class="header-nav-icon">
            <img
              src="../assets/img/search.png"
              title="点击进行全站搜索"
              @click="showSearch = true"
              alt
            />
          </div>
        </div>
      </div>
      <div class="nav-hiden-list" :class="{active: showNav}">
        <a href="/">首页</a>
        <a :href="nav.link" v-for="nav in navlist"
            :key="nav.key+'nav'">{{nav.title}}</a>
      </div>
      <div class="search-container" :class="{ active: showSearch }">
        <div class="search-content">
          <span
            class="close iconfont icon-guanbi"
            @click="showSearch = false"
          ></span>
          <input
            type="text"
            v-model="searchTxt"
            @keyup.enter="search"
            placeholder="请输入关键词"
          />
          <span
            class="bg_search iconfont icon-dasousuo"
            @click="search"
            title="点击进行搜索"
          ></span>
        </div>
      </div>
    </header>
    <div class="side-bar">
      <div class="side-item">
        <div class="side-line phone">
          <div>
            <span class="iconfont icon-shouji"></span>
            <span>400电话</span>
          </div>
          <span
            ><a :href="'tel:' + websiteInfo.contactPhone" style="color: #fff">{{
              websiteInfo.contactPhone
            }}</a></span
          >
        </div>
      </div>
      <div class="side-item">
        <div class="side-line">
          <div>
            <span class="iconfont icon-erweima"></span>
            <span>客服微信</span>
          </div>
          <span></span>
        </div>
        <div class="side-box">
          <img :src="websiteInfo.serviceWx" alt />
        </div>
      </div>
      <div class="side-item">
        <div class="side-line">
          <div>
            <span class="iconfont icon-erweima"></span>
            <span>农极客APP</span>
          </div>
          <span></span>
        </div>
        <div class="side-box">
          <img :src="websiteInfo.app" alt />
        </div>
      </div>
      <div class="side-item" v-show="showScrollTop">
        <div class="side-line" @click="toTop">
          <div>
            <span class="iconfont icon-huidingbu"></span>
            <span>返回顶部</span>
          </div>
          <span></span>
        </div>
      </div>
    </div>

    <section class="connect-window">
      <div class="connect-header">
        <span class="connect-kefu iconfont icon-kefu"></span>
        <span>联系我们</span>
        <span
          v-if="showWindow"
          @click="closeWindow"
          alt
          class="connect-icon iconfont icon-danchuangguanbi"
        ></span>
        <span
          v-else
          @click="showWindow = true"
          alt
          style="transform: rotate(180deg)"
          class="connect-icon iconfont icon-shouyexiala"
        ></span>
      </div>
      <div class="connect-body" v-if="showWindow && !sended">
        <div class="connect-line">
          <span class="connect-title">姓名：</span>
          <el-input
            class="short"
            placeholder="请输入姓名"
            v-model="name"
            type="text"
            clearable
          ></el-input>
          <span class="connect-title" style="margin-left: 18px">身份：</span>
          <el-select class="short" v-model="job" placeholder="请选择">
            <el-option label="种植户" value="种植户"></el-option>
            <el-option label="经销商" value="经销商"></el-option>
            <el-option label="厂家" value="厂家"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </div>
        <div class="connect-line">
          <span class="connect-title">作物：</span>
          <el-input
            class="short"
            placeholder="种植作物"
            v-model="crop"
            type="text"
            clearable
          ></el-input>
          <span class="connect-title" style="margin-left: 18px">面积：</span>
          <el-input
            class="short"
            placeholder="种植面积"
            v-model="area"
            type="text"
            clearable
          ></el-input>
        </div>
        <div class="connect-line">
          <span class="connect-title">电话：</span>
          <el-input
            placeholder="请输入电话"
            v-model="phone"
            type="text"
            clearable
          ></el-input>
        </div>
        <div class="connect-line">
          <span class="connect-title">地址：</span>
          <el-input
            placeholder="请输入地址：省 / 市 / 区 / 县"
            v-model="address"
            type="text"
            clearable
          ></el-input>
        </div>
        <div class="connect-line" style="align-items: flex-start">
          <span class="connect-title">
            咨询
            <br />内容：
          </span>
          <el-input
            placeholder="请输入您想咨询的内容"
            v-model="msg"
            type="textarea"
            clearable
          ></el-input>
        </div>
        <div
          class="connect-send"
          element-loading-spinner="el-icon-loading"
          @click="clickSendHandle"
          v-loading="sending"
        >
          提交
        </div>
      </div>
      <div class="connect-body" v-if="showWindow && sended">
        <img src="../assets/img/ok.png" alt class="connect-success-icon" />
        <div class="connect-success-txt">
          已经收到您的留言，
          <br />我们会尽快回复！
        </div>
        <div class="connect-success-close">
          <el-button size="mini" @click="closeWindow">关闭窗口</el-button>
        </div>
        <div class="connect-success-interval">
          {{ intervalNum }}秒后会自动关闭该窗口
        </div>
      </div>
    </section>
  </el-container>
</template>

<script>
import $ from "../assets/js/jquery.min.js";
import "../assets/css/iconfont.css";
export default {
  data() {
    let USER = !sessionStorage["USER"]
      ? {}
      : JSON.parse(sessionStorage["USER"]);

    let hideWindow = sessionStorage["hideWindow"];
    return {
      USER: USER,
      hideWindow: hideWindow,
      name: "",
      phone: "",
      address: "",
      job: "种植户",
      crop: "",
      area: "",
      msg: "",
      showWindow: false,
      showNav: false,
      sending: false,
      sended: false,
      intervalNum: 8,
      activeIndex: "/",
      showScrollTop: false,
      year: new Date().getFullYear(),
      navlist: [
        {
          title: "农技知识",
          key: "njzs",
          link: "njzs.html",
        },
        {
          title: "病虫害图谱",
          key: "bch",
          link: "bch.html",
        },
        {
          title: "农资商城",
          key: "nzsc",
          link: "nzsc.html?type=1",
          children: [
            {
              title: "热销爆款",
              link: "nzsc.html?type=1",
            },
            {
              title: "根施专区",
              link: "nzsc.html?type=2",
            },
            {
              title: "叶喷专区",
              link: "nzsc.html?type=3",
            },
            {
              title: "套餐专区",
              link: "nzsc.html?type=4",
            },
          ],
        },
        {
          title: "应用案例",
          key: "al",
          link: "zwal.html",
          // children: [
          //   {
          //     title: "产品案例",
          //     link: "cpal.html",
          //   },
          //   {
          //     title: "作物案例",
          //     link: "zwal.html",
          //   },
          // ],
        },
        {
          title: "互动社区",
          key: "sq",
          link: "hdsq.html",
          children: [
            {
              title: "活动",
              link: "hdsq.html",
            },
            {
              title: "问答",
              link: "wdsq.html",
            },
          ],
        },
        {
          title: "猫友部落",
          key: "bl",
          link: "bl.html",
        },
        {
          title: "新闻中心",
          key: "xw",
          link: "xwzx.html?type=0",
          children: [
            {
              title: "企业动态",
              link: "xwzx.html?type=0",
            },
            {
              title: "行业新闻",
              link: "xwzx.html?type=1",
            },
          ],
        },
        {
          title: "联系我们",
          key: "_u",
          link: "gywm_u.html",
          children: [
            {
              title: "关于我们",
              link: "gywm_u.html",
            },
            {
              title: "招商合作",
              link: "zshz_u.html",
            },
            {
              title: "招贤纳士",
              link: "zxns_u.html",
            },
            {
              title: "销售网点",
              link: "xswd_u.html",
            },
            {
              title: "在线留言",
              link: "zxly_u.html",
            },
          ],
        },
      ],
      application: {
        title: "应用案例",
        router: "/application/prolist",
        key: "application",
        child: [
          {
            name: "市场反馈",
            router: "/application/prolist",
          },
          {
            name: "作物管理方案",
            router: "/application/croplist",
          },
        ],
      },
      society: {
        title: "社区",
        router: "/society/asklist",
        key: "society",
        child: [
          {
            name: "农技问答",
            router: "/society/asklist",
          },
          {
            name: "活动",
            router: "/society/activelist",
          },
        ],
      },
      componey: {
        title: "联系我们",
        router: "/componey/aboutus",
        key: "componey",
        child: [
          {
            name: "关于我们",
            router: "/componey/aboutus",
          },
          {
            name: "招商",
            router: "/componey/business",
          },
          {
            name: "招聘",
            router: "/componey/recruit",
          },
          {
            name: "销售网点",
            router: "/componey/map",
          },
          {
            name: "在线联系",
            router: "/componey/conect",
          },
        ],
      },
      product: {
        title: "产品中心",
        router: `/product/`,
        key: "product",
        child: [],
      },
      footerList: [],
      showSearch: false,
      searchTxt: "",
      websiteInfo: {
        app: "http://m.meitiannongzi.com/1598606092088.png",
        contactPhone: "400-800-8084",
        firmAddress: "河南省郑州市金水区金成国际广场",
        infoId: 1,
        serviceWx: "http://m.meitiannongzi.com/1598859105077.png",
      },
      path: location.href,
    };
  },
  //   computed: mapState(["product", "websiteInfo"]),
  // beforeRouteUpdate(to, from, next) {
  //   // just use `this`
  //   console.log("beforeRouteUpdate", to);
  //   // this.updatepath();
  //   // setTimeout(() => {
  //   //   this.updatepath();
  //   // }, 400);
  //   next();
  // },
  methods: {
    closeWindow() {
      sessionStorage["hideWindow"] = true;
      this.hideWindow = true;
      this.showWindow = false;
    },
    quitLogin() {
      sessionStorage["USER"] = "";
      this.USER = {};
      this.$message.success("已退出登录");
    },
    search() {
      console.log(this.searchTxt);
      if (this.searchTxt == "") {
        this.$message.error("搜索关键词不能为空！");
        return false;
      }
      this.showSearch = false;
      location.href = `search.html?key=${this.searchTxt}`;
    },
    toTop() {
      $("html,body").animate({ scrollTop: "0px" }, 300);
    },
    clickSendHandle() {
      if (!this.name) {
        this.$alert("请输入您的姓名", "缺少必填项");
        return false;
      } else if (!this.phone) {
        this.$alert("请输入您的电话", "缺少必填项");
        return false;
      } else if (!this.address) {
        this.$alert("请输入地址", "缺少必填项");
        return false;
      } else if (!this.msg) {
        this.$alert("请输入咨询内容", "缺少必填项");
        return false;
      }
      if (this.sending) {
        return false;
      }
      this.sending = true;
      console.log("click");
      this.$axios
        .post(
          `contact/addContact`,
          {
            name: this.name,
            phone: this.phone,
            address: this.address,
            job: this.job,
            msg: this.msg,
            clickStatus: 0,
            crop: this.crop,
            area: this.area,
          },
          {
            header: {
              contentType: "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.sending = false;
          if (res.status == 200) {
            this.$alert("提交成功！稍后会有客服和您联系。");
            this.sended = true;
            let timer = setInterval(() => {
              this.intervalNum--;
              if (this.intervalNum <= 0) {
                clearInterval(timer);
                this.showWindow = false;
                this.sended = false;
                this.intervalNum = 8;
                this.msg = "";
                this.name = "";
                this.phone = "";
                this.address = "";
                this.crop = "";
                this.area = "";
              }
            }, 1000);
          } else {
            this.$alert(`提交失败！${res.information}`);
          }
        });
    },
  },
  created() {
    this.activeIndex = this.path;
  },
  mounted() {
    let _this = this;
    window.onscroll = function () {
      //为了保证兼容性，这里取两个值，哪个有值取哪一个
      //scrollTop就是触发滚轮事件时滚轮的高度
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 100) {
        _this.showScrollTop = true;
      } else {
        _this.showScrollTop = false;
      }
    };

    // let arr = [];
    if (
      !!this.$store.state.product &&
      !!this.$store.state.product.child &&
      this.$store.state.product.child.length > 0
    ) {
      return;
    } else {
      //   this.$axios.get("category/findProCategoryList").then((data) => {
      //     data = data.data;
      //     for (var i in data.information) {
      //       if (data.information[i].categoryStatus == 0) {
      //         // 0 正常   1 禁用
      //         arr.push({
      //           name: data.information[i].categoryName,
      //           router: `/product/${data.information[i].categoryId}`,
      //           id: data.information[i].categoryId,
      //           hover: false,
      //         });
      //       }
      //     }
      //     let product = {
      //       title: "产品中心",
      //       router: `/product/${arr[0].id}`,
      //       key: "product",
      //       id: "product",
      //       child: arr,
      //     };
      //     this.$store.commit("setproduct", product);
      //   });
    }
    this.$axios.get("info/websiteInfo").then((res) => {
      let data = res.data;
      console.log(data);
    });
    // this.$axios.post(
    //   "/api/urls?site=https://www.nongjike.cn&token=U4KYKk3rVbpFf0sT"
    // );
    // this.$axios.get(`ask/websiteAskList?pageNum=1&pageSize=10`).then((res) => {
    //   let data = res.data;
    //   this.$store.commit("setasklist", data.information.list);
    //   this.$store.commit("setasktotal", data.information.total);
    // });
    setTimeout(() => {
      //
      var cnzz_s_tag = document.createElement("script");
      cnzz_s_tag.type = "text/javascript";
      cnzz_s_tag.async = true;
      cnzz_s_tag.charset = "utf-8";
      cnzz_s_tag.src =
        "https://s9.cnzz.com/z_stat.php?id=1279234561&web_id=1279234561";
      var root_s = document.getElementsByTagName("script")[0];
      root_s.parentNode.insertBefore(cnzz_s_tag, root_s);

      //腾讯移动分析
      (function () {
        var mta = document.createElement("script");
        mta.src = "//pingjs.qq.com/h5/stats.js?v2.0.4";
        mta.setAttribute("name", "MTAH5");
        mta.setAttribute("sid", "500728697");
        mta.setAttribute("cid", "500729044");
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(mta, s);
      })();
      (function () {
        // 百度站长自动收录
        var bp = document.createElement("script");
        var curProtocol = window.location.protocol.split(":")[0];
        if (curProtocol === "https") {
          bp.src = "https://zz.bdstatic.com/linksubmit/push.js";
        } else {
          bp.src = "http://push.zhanzhang.baidu.com/push.js";
        }
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(bp, s);
      })();
      (function () {
        //360自动收录
        var bp = document.createElement("script");
        bp.src =
          "https://jspassport.ssl.qhimg.com/11.0.1.js?d182b3f28525f2db83acfaaf6e696dba";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(bp, s);
      })();
    }, 500);
    setTimeout(() => {
      if (this.hideWindow) {
        return false;
      }
      this.showWindow = true;
    }, 2000);
  },
};
</script>

<style lang="scss">
#app .el-textarea__inner:focus {
  border-color: #65b725;
}
#app .el-input.is-active .el-input__inner,
#app .el-input__inner:focus {
  border-color: #65b725;
}
.header-icon img {
  // width: 308px;
  height: 40px;
}
.connect-window {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  width: 328px;
  min-height: 40px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.4);
  .connect-header {
    width: 328px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #65b725;
    padding: 0 16px;
    span {
      flex-grow: 1;
      font-size: 16px;
      color: #fff;
    }
    .connect-kefu {
      width: 20px;
      height: 20px;
      font-size: 20px;
      line-height: 20px;
      color: #fff;
      display: block;
      margin-right: 7px;
      flex-grow: 0;
    }
    .connect-icon {
      width: 18px;
      height: 18px;
      font-size: 18px;
      line-height: 18px;
      color: #fff;
      display: block;
      cursor: pointer;
      flex-grow: 0;
    }
  }
  .connect-body {
    background: #fff;
    width: 328px;
    // height: 312px;
    padding: 8px 16px 24px;
    .title {
      font-size: 14px;
      color: #666666;
      line-height: 20px;
    }
    .connect-line {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 16px 0;
      .connect-title {
        font-size: 14px;
        color: #1f1f1f;
        width: 45px;
        display: block;
      }
      .el-input__inner {
        width: 96px;
        height: 32px;
        padding: 0 9px;
      }
      .el-input__icon {
        line-height: 32px;
      }
      .el-textarea {
        width: 254px;
        .el-textarea__inner {
          width: 254px;
          height: 64px;
          padding: 9px 9px;
        }
      }
      .el-input {
        width: 254px;
        .el-input__inner {
          width: 254px;
          height: 32px;
          padding: 0 9px;
        }
      }
      .short {
        .el-input {
          width: 96px;
        }
        width: 96px;
        height: 32px;
        .el-input__inner {
          width: 96px;
          height: 32px;
          padding: 0 9px;
        }
      }
    }
    .connect-send {
      width: 296px;
      height: 32px;
      line-height: 32px;
      margin-top: 24px;
      text-align: center;
      color: #fff;
      background: #65b825;
      cursor: pointer;
      border-radius: 4px;
      .el-loading-spinner {
        margin-top: -15px;
      }
    }
    .connect-success-icon {
      width: 80px;
      height: 80px;
      margin: 40px auto 12px;
      display: block;
    }
    .connect-success-txt {
      font-size: 14px;
      color: #1e1e1e;
      line-height: 22px;
      margin: 0 auto 34px;
      text-align: center;
    }
    .connect-success-close {
      text-align: center;
      .el-button {
        border: 1px solid #ccc;
        font-size: 14px;
        color: #666;
      }
    }
    .connect-success-interval {
      font-size: 14px;
      color: #666;
      text-align: center;
      margin-top: 4px;
    }
  }
}
.side-bar {
  position: fixed;
  z-index: 15;
  right: 0;
  top: 240px;
  cursor: pointer;
  width: 78px;
  .side-item {
    margin-bottom: 1px;
    position: relative;
    .side-box {
      visibility: hidden;
      width: 156px;
      height: 156px;
      position: absolute;
      z-index: 10;
      left: -168px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: all 0.3s ease-in-out;
      img {
        width: 156px;
        height: 156px;
        display: block;
      }
    }
    &:hover {
      .side-box {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .side-line {
    height: 68px;
    // line-height: 68px;
    background: #65b825;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    position: relative;
    right: -10px;
    transition: all 0.35s ease-in-out;
    width: 78px;
    &.phone {
      // right: -146px;
      width: 214px;
      &:hover {
        right: 136px;
      }
    }
    &:hover {
      right: 0;
    }
    & > div {
      width: 68px;
      height: 68px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      img {
        width: 24px;
        height: 24px;
        display: block;
        margin-bottom: 2px;
      }
      .iconfont {
        color: #fff;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 2px;
        display: block;
      }
      span {
        font-size: 12px;
      }
    }
    &.phone > span {
      display: block;
      width: 146px;
      text-align: center;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 1px;
        height: 40px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #fff;
      }
    }
    & > span {
      display: block;
      width: 10px;
    }
  }
}
.search-container {
  position: absolute;
  width: 100vw;
  background: #fff;
  left: 0;
  top: 120px;
  z-index: 10;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  &.active {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
  .search-content {
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 160px;
    margin: 0 auto;
    input {
      width: 900px;
      height: 64px;
      line-height: 64px;
      padding: 0 60px 0 16px;
      border: none;
      border-bottom: 1px solid #e6e6e6;
      font-size: 16px;
      color: #343434;
      box-shadow: none;
      outline: none;
    }
    .close {
      position: absolute;
      top: 17px;
      right: 0;
      width: 24px;
      height: 24px;
      font-size: 24px;
      line-height: 24px;
      color: #666666;
      display: block;
      cursor: pointer;
    }
    .bg_search {
      position: absolute;
      top: 64px;
      right: 165px;
      width: 34px;
      height: 34px;
      font-size: 34px;
      line-height: 34px;
      color: #65b825;
      display: block;
      cursor: pointer;
    }
  }
}
header {
  background: #fff;
  height: 121px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  z-index: 8;
}
.login_container {
  width: 100%;
  background: #f5f5f5;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  z-index: 11;
}
.login_header {
  width: 1200px;
  margin: 0 auto;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 11;
  .login_t {
    font-size: 14px;
    color: #666666;
  }
  .login_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: #666666;
    .login_name {
      margin-right: 10px;
    }
    a {
      text-decoration: none;
      color: #666666;
      &:hover {
        color: #66b825;
      }
    }
  }
}
.headers {
  margin: 0 auto;
  width: 1200px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  z-index: 11;
  .headers-icon img {
    width: 308px;
    height: 40px;
    display: block;
    padding: 0;
    margin: 0;
  }
  .header-nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    .header-nav-icon {
      width: 76px;
      line-height: 24px;
      font-size: 16px;
      text-align: right;
      position: relative;
      img {
        width: 36px;
        height: 36px;
        display: block;
        margin: 0 0 0 40px;
        cursor: pointer;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 1px;
        height: 16px;
        transform: translateY(-50%);
        background: #e6e6e6;
      }
    }
    .header-nav-item {
      width: 96px;
      line-height: 90px;
      font-size: 16px;
      height: 90px;
      text-align: center;
      position: relative;
      &:hover {
        color: #65b825;
        a {
          color: #65b825;
        }
        .nav-items {
          visibility: visible;
          transition: all 0.3s ease-in-out;
          opacity: 1;
        }
        &:after {
          background: #65b825;
        }
      }
      &.active {
        > a {
          color: #65b825;
        }
        &:after {
          background: #65b825;
        }
      }
      .nav-items {
        width: 96px;
        position: absolute;
        font-size: 14px;
        color: #fff;
        background: transparent;
        visibility: hidden;
        opacity: 0;
        top: 90px;
        left: 0;
        a {
          height: 46px;
          display: block;
          width: 96px;
          line-height: 46px;
          color: #fff;
          background: rgba(102, 184, 38, 0.7);
          text-decoration: none;
          transition: all 0.3s ease-in-out;
          &:hover {
            background: rgba(102, 184, 38, 1);
          }
        }
      }
      & > a {
        text-decoration: none;
        color: #343434;
        font-family: "微软雅黑";
        display: block;
        width: 96px;
        transition: all 0.3s ease-in-out;
        position: relative;
      }
      &:after {
        content: "";
        display: block;
        width: 30px;
        height: 4px;
        background: transparent;
        transition: all 0.3s ease-in-out;
        position: absolute;
        bottom: 14px;
        left: 50%;
        transform: translateX(-15px);
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 1px;
        height: 16px;
        transform: translateY(-50%);
        background: #e6e6e6;
      }
    }
  }
}
.el-container {
  min-width: 960px;
}

.el-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e1e1e;
  line-height: 56px;
  color: #cccccc;
  a {
    color: #cccccc;
    text-decoration: underline;
  }
}
.toggle-btn{
  display: none;
}
.nav-hiden-list{
  display: none;
}

@media screen and (max-width: 1200px) {
  #app{
    padding-bottom: 45px;
  }
  .swiper-cont {
    .swiper-button-prev {
      width: calc(2px * 56 / 3);
      height: calc(2px * 120 / 3);
    }
    .swiper-button-next {
      width: calc(2px * 56 / 3);
      height: calc(2px * 120 / 3);
    }
  }
  .side-bar {
    right: -16px;
    transform: scale(0.8);
    -ms-transform: scale(0.8); /* IE 9 */
    -moz-transform: scale(0.8); /* Firefox */
    -webkit-transform: scale(0.8); /* Safari 和 Chrome */
    -o-transform: scale(0.8); /* Opera */

    transform-origin: 0 0;
    -ms-transform-origin: 0 0; /* IE 9 */
    -moz-transform-origin: 0 0; /* Firefox */
    -webkit-transform-origin: 0 0; /* Safari 和 Chrome */
    -o-transform-origin: 0 0; /* Opera */
  }
  .connect-window {
    transform: scale(0.8);
    -ms-transform: scale(0.8); /* IE 9 */
    -moz-transform: scale(0.8); /* Firefox */
    -webkit-transform: scale(0.8); /* Safari 和 Chrome */
    -o-transform: scale(0.8); /* Opera */

    transform-origin: 0% 100%;
    -ms-transform-origin: 0 100; /* IE 9 */
    -moz-transform-origin: 0 100; /* Firefox */
    -webkit-transform-origin: 0 100; /* Safari 和 Chrome */
    -o-transform-origin: 0 100; /* Opera */
  }
  header {
    height: 91px;
  }
  .headers {
    width: 100%;
    padding-left: 10px;
    height: 60px;
    background: #fff;
    .header-nav{
      display: none;
    }
  }
  .toggle-btn{
    display: block;
    margin-right: 15px;
    border: 1px solid #666;
    padding: 0 5px;
    border-radius: 5px;
  }
  .card-detail{
    display: none;
  }
  .nav-hiden-list{
    width: 100%;
    display: block;
    position: absolute;
    top: -360px;
    left: 0;
    background: #fff;
    z-index: 10;
    transition: all .3s ease-in-out;
    &.active{
      top: 91px;
    }
    a{
      display: block;
      width: 100%;
      height: 45px;
      line-height: 45px;
      text-align: center;
      color:#333;
    }
  }
  .side-bar .side-line > div .iconfont{
    font-size: 16px;
  }
  .side-bar .side-line > div{
    width: 45px;
    height: 45px;
  }
  .side-bar .side-line{
    // right: -26px;
    width: 60px;
  }
  .side-bar{
    width: 60px;
    display: none;
  }
  .connect-window{
    display: none;
  }

  #app .research-links{
    margin: 30px 0;
    .research-link{
      width: 100%;
      margin: 10px 0 10px;
    }
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  #app .crop-title{
    font-size: 20px;
    padding-top: -0;
  }
}

  pre{
    white-space: pre-wrap;
    overflow: hidden;
  }
</style>
