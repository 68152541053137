<template>
  <el-pagination
    background
    :current-page="currentpage"
    @current-change="pageChange"
    layout="prev, pager, next"
    prev-text="上一页"
    next-text="下一页"
    :total="totalpage"
    :page-size="pagesize"
    :hide-on-single-page="true"
    :pager-count="pagerCount"
  ></el-pagination>
</template>

<script>
export default {
  props: ["totalpage", "current", "pagesize"],
  data() {
    return {
      currentpagesize: 10,
      currentpage: !this.current ? 1 : this.current,
      pagerCount: 7,
    };
  },
  computed: {
    // currentpage: {
    //   get() {
    //     return this.current;
    //   },
    //   set(val) {
    //     console.log(val);
    //   },
    // },
  },
  beforeMount() {
    if (window.innerWidth < 1400) {
      this.pagerCount = 5;
    }
  },
  methods: {
    pageChange(e) {
      this.currentpage = parseInt(e);

      this.$emit("pageChange", e);
    },
    handleSizeChange(e) {
      this.currentpagesize = e;
      this.$emit("pagesizeChange", e);
    },
  },
};
</script>

<style lang="scss">
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev {
  padding: 0 10px;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #65b825 !important;
  border-color: #65b825;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #65b825;
  border-color: #65b825;
  color: #fff;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #65b825 !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff !important;
}
.el-pagination {
  white-space: nowrap;
  padding: 2px 5px;
  color: #303133;
  font-weight: 700;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: #fff;
  border: 1px solid #e6e6e6;
  color: #666;
}
.el-pager,
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev {
  padding: 0 5px !important;
}
@media screen and (max-width: 1200px) {
  #app {
    .el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .el-pager li {
      margin: 0 3px;
      min-width: 25px;
    }
  }
}
</style>
