<template>
  <div class="prePage">
    <div class="pre-bar"></div>
    <div class="main-content">
      <div class="pre-page-item">
        <div class="pre-page-text">
          <span>{{limitedWidth?'当前位置：':'您所在的位置是：'}}</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="item in navList" :to="item.to" :key="item.name"><span v-if="!item.to">{{item.name}}</span><a :title="item.name" :href="item.to" v-else>{{item.name}}</a></el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <span class="pre-page-btn" @click="goPrepage">返回上级</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nav: {
      type: Array,
      required: true,
    },
  },
  data(){
    return {
      limitedWidth: false,
    }
  },
  computed: {
    navList(){
      return this.nav
    }
  },
  methods: {
    goPrepage() {
      history.go(-1);
    },
  },
  beforeMount() {
    if (window.innerWidth < 1400) {
      this.limitedWidth = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
  color:#666;
}
.el-breadcrumb__inner a:hover, .el-breadcrumb__inner.is-link{
  color:#65B825;
}
.prePage {
  width: 100%;
  .pre-bar {
    width: 100%;
    height: 4px;
    background: #e6e6e6;
  }
  .pre-page-item {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    border-bottom: 1px dashed #e6e6e6;
    padding-top: 16px;
    .pre-page-text {
      flex-grow: 1;
      padding-bottom: 16px;
      font-size: 14px;
      display: flex;
      font-size: 14px;
      align-items: flex-start;
      color: #666;
      &>span{
        flex-shrink: 0;
        line-height: 14px;
      }
    }
    .pre-page-btn {
      width: 80px;
      height: 30px;
      display: block;
      text-align: center;
      background-color: #65B825;
      font-size: 14px;
      line-height: 30px;
      color:#fff;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 1200px) {
  .prePage .pre-page-item{
    width: 100%;
    padding: 16px 10px 0;
  .pre-page-btn{
    display: none;
  }
  }
}
</style>