<template>
  <div class="adv">
    <img src="../assets/img/mtnz.png" alt="" />
    <div class="adv-info">
      <div>扫描二维码关注每天农资公众号，第一时间了解行业资讯</div>
      <div>转载请说明来源于“农极客官网”</div>
      <div>
        本文地址：<a :href="link">{{ link }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "adv",
  data: () => {
    return {
      link: location.href,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.adv {
  width: 100%;
  padding: 20px;
  background: #f5f5f5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 120px;
    height: 120px;
    display: block;
    margin-right: 16px;
  }
  .adv-info {
    font-size: 14px;
    line-height: 25px;
    color: #333;
    a {
      color: #65b825;
    }
  }
}
@media screen and (max-width: 1200px) {
  .adv{
    overflow: hidden;
    img{
      width: 100px;
      height: 100px;
    }
    .adv-info{
      font-size: 12px;
      overflow: hidden;
    }
  }
}
</style>
